<div class="df-page df-blogpost-page">
    <app-header></app-header>
    <div class="df-page-content container" *ngIf="blogPost">
        <div class="df-blogpost-bc"><span class="material-icons">speaker_notes</span> Blog</div>
        <h1 class="df-blogpost-title">{{ blogPost.title }}</h1>
        <div class="df-blogpost-author-block">
            <div class="df-blogpost-author-img" [style.background-image]="'url(' + blogPost.author.image.url + ')'"></div>
            <div class="df-blogpost-author-info">
                <div class="df-blogpost-author-name">por {{ blogPost.author.displayName }}</div>
                <div class="df-blogpost-entry-published">Publicado {{ blogPost.published | date : 'mediumDate' : '+400' : 'es-US' }}</div>
            </div>
        </div>
        <div style="margin-bottom: 32px;" class="sharethis-inline-share-buttons"></div>
        <div class="df-blogpost-content" [innerHtml]="blogPost.content"></div>
    </div>
    <app-footer></app-footer>
</div>
