import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit {

  navItems = [{
    route: 'cursos',
    icon: 'school',
    label: 'Cursos',
    visible: false
  },
  {
    route: 'libros',
    icon: 'menu_book',
    label: 'Libros',
    visible: true
  },
  {
    route: 'podcasts',
    icon: 'mic',
    label: 'Podcasts',
    visible: true
  },
  {
    route: 'videos',
    icon: 'video_library',
    label: 'Videos',
    visible: false
  },
  {
    route: 'blog',
    icon: 'speaker_notes',
    label: 'Blog',
    visible: true
  },
  {
    route: 'sobre-mi',
    icon: 'account_circle',
    label: 'Sobre Mí',
    visible: true
  },
  {
    route: 'contacto',
    icon: 'contacts',
    label: 'Contacto',
    visible: true
  }];

  showMobileNav: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

  toggleDrawer() {
    this.showMobileNav = !this.showMobileNav;
  }

  hideNav() {
    this.showMobileNav = false;
  }
}