<div class="df-page df-books">
    <app-header></app-header>
    <div class="df-page-content container">
        <h1 class="df-page-title"><span class="material-icons">menu_book</span> Libros</h1>
        <section class="books-section">
            <ng-container *ngFor="let book of booksList">
                <div class="book-item">
                    <div class="book-cover">
                        <img src="./assets/books/book{{ book.id }}cover.png" />
                    </div>
                    <div class="book-details">
                        <div class="book-info">
                            <div class="book-title">{{ book.title }} ({{ book.lang }})</div>
                            <div class="book-author">{{ book.author }}</div>
                            <div class="book-desc">{{ book.description }}</div>
                        </div>
                        <div class="book-sellers-info">
                            <div class="book-sellers-container">
                                <ng-container *ngFor="let seller of book.sellers">
                                    <div class="seller-item">
                                        <a class="seller-link" href="{{ seller.link }}" target="_blank">{{ seller.prelabel }} <img src="./assets/books/sellers/{{ seller.name }}.png" /></a>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </ng-container>
        </section>
        <br/>
        <h1 class="df-page-title"><span class="material-icons">menu_book</span> E-Books</h1>
        <section class="books-section">
            <ng-container *ngFor="let ebook of ebookList">
                <div class="df-ebook-row">
                    <div class="df-ebook-row-img">
                        <img src="{{ './assets/books/' + ebook.thumbnail + '.png' }}" />
                    </div>
                    <div class="df-ebook-row-details">
                        <h2>{{ ebook.title }}</h2>
                        <div><b>Total de Páginas:</b> {{ ebook.totalPages }}</div>
                        <div><b>Formato:</b> {{ ebook.format }}</div>
                        <div><b>Descripción: </b> {{ ebook.description }}</div>
                        <div class="df-ebook-download-links">
                            <ng-container *ngFor="let ebookLink of ebook.links">
                                <a target="_blank" href="{{ ebookLink.url }}">{{ ebookLink.label }}</a>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </ng-container>
        </section>
    </div>
    <app-footer></app-footer>
</div>

