import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IPodcastEpisode } from 'src/app/models/podcast-episode';

@Component({
  selector: 'app-podcasts-page',
  templateUrl: './podcasts-page.component.html',
  styleUrls: ['./podcasts-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PodcastsPageComponent implements OnInit {

  selectedEpisode: IPodcastEpisode;
  episodes: IPodcastEpisode[] = [];

  constructor() { }

  ngOnInit(): void {
    this.episodes = [{
      id: '1',
      epNum: '1',
      title: 'Introducción Al Podcast',
      desc: 'En este podcast, Dorcaly Fiallo introduce el primer capitulo de las Pildoras Educativas; hace una breve descripcion autobiografica y les informa de los contenidos que estaremos tratando.',
      isPlaying: false,
      published: '2020-06-15',
      path: 'http://www.dorcalyfiallo.com/media/audio/df_web_intro.mp3'
    },
    {
      id: '2',
      epNum: '2',
      title: 'Auto-Cuidado Para Mamá',
      desc: 'En este podcast estaremos hablando sobre la importancia de que mamá tenga sus cuidados, conocerse a sí misma, recargar energías y regular sus emociones para ella, sus hijos y familia. El auto-cuidado es sinónimo de bienestar emocional, físico y espiritual. Que este 2021 seas tú la prioridad.',
      isPlaying: false,
      published: '2021-01-15',
      path: 'http://www.dorcalyfiallo.com/media/audio/PodcastAutocuidado.mp3'
    }
    /*{
      id: '2',
      epNum: '2',
      title: 'When your kids don\'t want to listen',
      desc: 'In this episode, Dorcaly discusses with clinican Paola Gomez About how to teach our kids how to deal with anxiety during these difficult times and how to make the best of it.',
      isPlaying: false,
      published: '2020-04-19'
    },
    {
      id: '3',
      epNum: '3',
      title: 'Home activites for the whole family',
      desc: 'In this episode, Dorcaly discusses with clinican Paola Gomez About how to teach our kids how to deal with anxiety during these difficult times and how to make the best of it.',
      isPlaying: false,
      published: '2020-04-19'
    },
    {
      id: '4',
      epNum: '4',
      title: 'How to keep the kiddos busy',
      desc: 'In this episode, Dorcaly discusses with clinican Paola Gomez About how to teach our kids how to deal with anxiety during these difficult times and how to make the best of it.',
      isPlaying: false,
      published: '2020-04-19'
    },
    {
      id: '5',
      epNum: '5',
      title: 'Engaging activites at home during quarantine',
      desc: 'In this episode, Dorcaly discusses with clinican Paola Gomez About how to teach our kids how to deal with anxiety during these difficult times and how to make the best of it.',
      isPlaying: false,
      published: '2020-04-19'
    },
    {
      id: '6',
      epNum: '6',
      title: 'Engaging activites at home during quarantine',
      desc: 'In this episode, Dorcaly discusses with clinican Paola Gomez About how to teach our kids how to deal with anxiety during these difficult times and how to make the best of it.',
      isPlaying: false,
      published: '2020-04-19'
    }*/
  ];
  }

  onSelectedPodcast(episode: IPodcastEpisode) {
    this.selectedEpisode = episode;
    this.episodes.forEach((e) => {
      e.isPlaying = episode.id === e.id;
    });
  }

  trackByMethod(index:number, el:IPodcastEpisode): string {
    return el.id;
  }
}
