<div class="df-free-download-banner">
    <div class="df-free-download-banner-inner container">
        <img src="./assets/imgs/coloring_book_img.png" />
        <div class="df-fd-container">
            <div class="df-fd-label1">Descarga el libro de colorear de</div>
            <div class="df-fd-label2">La Abejita Que No Quería Escuchar</div>
            <div class="df-fd-label3">GRATIS!!!</div>
            <div class="df-download-section">
                <a target="_blank" href="{{ mediaFile_EN }}">Download in English</a>
                <a target="_blank" href="{{ mediaFile_ES }}">Descargar en Español</a>
            </div>
        </div>
    </div>
</div>