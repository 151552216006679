import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { BooksPageComponent } from './pages/books-page/books-page.component';
import { CoursesPageComponent } from './pages/courses-page/courses-page.component';
import { VideosPageComponent } from './pages/videos-page/videos-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { SingleBlogPageComponent } from './pages/single-blog-page/single-blog-page.component';
import { PodcastsPageComponent } from './pages/podcasts-page/podcasts-page.component';
import { TitoBookPageComponent } from './pages/tito-book-page/tito-book-page.component';
import { TitoAppPrivacyComponent } from './pages/tito-app-privacy/tito-app-privacy.component';


const routes: Routes = [
  {
    component: HomePageComponent,
    path: 'inicio'
  },
  {
    component: AboutPageComponent,
    path: 'sobre-mi'
  },
  {
    component: BlogPageComponent,
    path: 'blog'
  },
  {
    component: SingleBlogPageComponent,
    path: 'blog/:id'
  },
  {
    component: BooksPageComponent,
    path: 'libros'
  },
  {
    component: PodcastsPageComponent,
    path: 'podcasts'
  },
  {
    component: ContactPageComponent,
    path: 'contacto'
  },
  {
    component: CoursesPageComponent,
    path: 'cursos'
  },
  {
    component: VideosPageComponent,
    path: 'videos'
  },
  {
    component: TitoBookPageComponent,
    path: 'titospecialplace'
  },
  {
    component: TitoAppPrivacyComponent,
    path: 'tito-app-privacy'
  },
  {
    path: '',
    redirectTo: '/inicio',
    pathMatch: 'full'
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
