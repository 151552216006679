<div class="df-page df-contact">
    <app-header></app-header>
    <div class="df-page-content container">
        <h1 class="df-page-title"><span class="material-icons">contacts</span> Contacto</h1>
        <section class="contact-section">
            <div class="contact-title">Si quieres contactarme, puedes hacerlo a traves de las diversas redes sociales:</div>
            <div class="social-media-link fb">
                <a target="_blank" href="https://www.facebook.com/dorcaly.fiallo"><i class="fab fa-facebook"></i>&nbsp;&nbsp;Facebook</a>
            </div>
            <div class="social-media-link tw">
                <a target="_blank" href="https://twitter.com/dorcalyfiallo"><i class="fab fa-twitter"></i>&nbsp;&nbsp;Twitter</a>
            </div>
            <div class="social-media-link ig">
                <a target="_blank" href="https://www.instagram.com/dorcaly_fiallo/"><i class="fab fa-instagram"></i>&nbsp;&nbsp;Instagram</a>
            </div>
        </section>
    </div>
    <app-df-download-banner></app-df-download-banner>
    <app-footer></app-footer>
</div>