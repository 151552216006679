<div class="df-page df-tito-page">
    <app-header></app-header>
    <div class="df-page-content container">
        <img class="tito_icon" src="./assets/imgs/titoicon.png" />
        <h1 class="df-page-title">El Lugar Especial de Tito / Tito's Special Place</h1>
        <h2>Companion App / Aplicación Móvil Complementaria</h2>
        <br/>
        <h2>Available Now!</h2>
        <p>The book "Tito's Special Place" includes a companion app containing interactive exercises that will encourage young readers to practice expressing their emotions through the zone of emotional regulation, focus games and other tips and tricks.</p>
        <p>The app is available for tablets only, for both IOS and Android.</p>
        <br/>
        <h2>¡Disponible Ya!</h2>
        <p>El Libro "El Lugar Especial de Tito" incluye una aplicación móvil complementaria que contiene ejercicios interactivos que alentarán a los jóvenes lectores a practicar la expresión de sus emociones a través de la zona de regulación emocional, juegos de enfoque y otros consejos y trucos.</p>
        <p>La aplicación está disponible para tabletas solamente, tanto para IOS como para Android.</p>
        <br/>
        <div class="appstore-badges"><a href="https://apps.apple.com/us/app/tito-special-place/id6469473344">
            <img class="appstore-badge" src="./assets/imgs/appstore.png" />
        </a><a href="https://play.google.com/store/apps/details?id=com.drcoderz.titospecialplace&pcampaignid=web_share">
            <img class="appstore-badge" src="./assets/imgs/googleplay.png" />
        </a></div>
        <br/>
        <iframe class="tito-video" src="https://www.youtube.com/embed/QsiclHv8eP8?si=S425f2YeJHI5lbVk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    <app-footer></app-footer>
</div>