<footer class="df-footer">
    <div class="df-footer-logos">
        <a [routerLink]="['/inicio']"><img class="dorcaly-logo" src="./../../../assets/imgs/dorcaly_logo.png" /></a>
        <img class="dorcaly-signature" src="./../../../assets/imgs/df_signature.png" />
    </div>
    <div class="df-footer-nav">
        <app-navigation></app-navigation>
    </div>
    <div class="df-footer-social">
        <app-social-media-links></app-social-media-links>
    </div>
</footer>
