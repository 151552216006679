import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { IBlogModel } from 'src/app/models/blog-model';

@Component({
  selector: '[blog-entry]',
  templateUrl: './blog-entry.component.html',
  styleUrls: ['./blog-entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogEntryComponent implements OnInit {

  @Input() blog: IBlogModel;
  
  constructor() { }

  ngOnInit(): void {
  }

}
