import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dorcaly-card',
  templateUrl: './dorcaly-card.component.html',
  styleUrls: ['./dorcaly-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DorcalyCardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
