import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tito-book-page',
  templateUrl: './tito-book-page.component.html',
  styleUrls: ['./tito-book-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TitoBookPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
