import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-videos-page',
  templateUrl: './videos-page.component.html',
  styleUrls: ['./videos-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideosPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
