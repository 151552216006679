<div class="df-blog-entry-img" [style.background-image]="'url(' + blog.blogImg + ')'">
</div>
<div class="df-blog-entry-content-wrapper">
    <div class="df-blog-entry-content">
        <div class="df-blog-entry-title">{{ blog.title }}</div>
        <div class="df-blog-entry-desc">{{ blog.description }}</div>
    </div>
    <div class="df-blog-entry-author-block">
        <div class="df-blog-author-img" [style.background-image]="'url(' + blog.author.image.url + ')'"></div>
        <div class="df-blog-author-info">
            <div class="df-blog-author-name">por {{ blog.author.displayName }}</div>
            <div class="df-blog-entry-published">Publicado {{ blog.published | date : 'mediumDate' : '+400' : 'es-US' }}</div>
        </div>
    </div>
</div>