<div class="df-blogs-container container">
    <h1>Blogs</h1>
    <div class="df-blogs" *ngIf="blogPayload">
        <ng-container *ngFor="let blog of blogPayload.items" >
            <div (click)="goToBlogPage(blog.id)" class="df-blog-entry" blog-entry [blog]="blog"></div>
        </ng-container>
    </div>
    <div class="more-blogs-link-wrapper">
        <a class="more-blogs-link" [routerLink]="['/blog']"><div class="df-blogs-more-link"><span class="material-icons">arrow_forward</span>Mas&nbsp;<b>Blogs</b>...</div></a>
    </div>
</div>
