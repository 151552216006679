import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { IBlogModel, IBlogPayload } from '../models/blog-model';
import { IBlogPage } from '../models/blog-page';

const API_ENDPOINT = 'https://www.googleapis.com/blogger/v3/blogs';
const API_KEY = 'AIzaSyC1XEGCDNfBJlhKZxsSLQNCUnLgSgm4OS8';
const BLOG_ID = '7624709652790506905';

@Injectable({
  providedIn: 'root'
})
export class ServiceProxyService {

  constructor(private httpClient: HttpClient) { }

  getBlogPosts(limit: number = 0) : Observable<IBlogPayload> {
    return Observable.create((observer: Observer<IBlogPayload>) => {
      this.httpClient.get(
        `${API_ENDPOINT}/${BLOG_ID}/posts/?key=${API_KEY}`).subscribe((response: IBlogPayload) => {
        var processedResponse = this.processBlogEntryResponse(response, limit);
        observer.next(processedResponse);
        observer.complete();
      });
    })
  }

  getSingleBlogPostById(blogPostId: string) : Observable<IBlogModel> {
    return Observable.create((observer: Observer<IBlogModel>) => {
      this.httpClient.get(
        `${API_ENDPOINT}/${BLOG_ID}/posts/${blogPostId}?key=${API_KEY}`).subscribe((response: IBlogModel) => {
        observer.next(response);
        observer.complete();
      });
    })
  }

  processBlogEntryResponse(response: any, limit: number) {

    response.items.forEach((blog) => {
      let tempDOM = document.createElement("div");
      tempDOM.innerHTML = blog.content;
      blog.blogImg = (tempDOM.querySelector('.df-img img') as HTMLImageElement).src;
      blog.description = (tempDOM.querySelector('.df-desc') as HTMLDivElement).innerText;
    });

    if (limit > 0) {
      response.items = response.items.slice(0, limit);
    }

    return response;
  }

  getPageContent(pageName: string) : Observable<IBlogPage> {
    return Observable.create((observer: Observer<IBlogPage>) => {
      this.httpClient.get(
        `${API_ENDPOINT}/${BLOG_ID}/pages/${pageName}/?key=${API_KEY}`).subscribe((response: IBlogPage) => {
        observer.next(response);
        observer.complete();
      });
    })
  }
}
