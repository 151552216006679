import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IBlogModel } from 'src/app/models/blog-model';
import { ServiceProxyService } from 'src/app/services/service-proxy.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-single-blog-page',
  templateUrl: './single-blog-page.component.html',
  styleUrls: ['./single-blog-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SingleBlogPageComponent implements OnInit {

  blogPost: IBlogModel;
  blogPostId: string;

  constructor(private route: ActivatedRoute, private serviceProxy: ServiceProxyService) { }

  ngOnInit(): void {
    this.blogPostId = this.route.snapshot.paramMap.get('id');
    this.serviceProxy.getSingleBlogPostById(this.blogPostId).subscribe((blog: IBlogModel) => {
      this.blogPost = blog;
    });
  }
}
