import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { IBlogPage } from 'src/app/models/blog-page';
import { ServiceProxyService } from 'src/app/services/service-proxy.service';
import { PageConstants } from 'src/app/constants/page-constants';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AboutPageComponent implements OnInit {

  blogPage: IBlogPage;

  constructor(private serviceProxy: ServiceProxyService) { }

  ngOnInit(): void {
    this.serviceProxy.getPageContent(PageConstants.ABOUT_ME).subscribe((blogPage: IBlogPage) => {
      this.blogPage = blogPage;
    });
  }

}
