import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  styleUrls: ['./announcement-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnnouncementBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
