import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-equalizer-svg',
  templateUrl: './equalizer-svg.component.html',
  styleUrls: ['./equalizer-svg.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EqualizerSvgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
