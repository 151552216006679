<div class="df-categories-container container">
    <div class="df-cat df-cat-infant">
        <img src="./../../../../assets/imgs/infant_banner.png" />
        <div class="df-cat-content">
            <div>Infantes</div>
            <div>(0-2 años)</div>
        </div>
    </div>
    <div class="df-cat df-cat-toddler">
        <img src="./../../../../assets/imgs/toddler_banner.png" />
        <div class="df-cat-content">
            <div>Niñitos/as</div>
            <div>(2-3 años)</div>
        </div>
    </div>
    <div class="df-cat df-cat-children">
        <img src="./../../../../assets/imgs/child_banner.png" />
        <div class="df-cat-content">
            <div>Niños/as</div>
            <div>(4-6 años)</div>
        </div>
    </div>
</div>