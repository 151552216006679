import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ServiceProxyService } from 'src/app/services/service-proxy.service';
import { IBlogPayload, IBlogModel } from 'src/app/models/blog-model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs-banner',
  templateUrl: './blogs-banner.component.html',
  styleUrls: ['./blogs-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BlogsBannerComponent implements OnInit {

  blogPayload: IBlogPayload;

  constructor(private router: Router, private serviceProxy: ServiceProxyService) { }

  ngOnInit(): void {
    this.serviceProxy.getBlogPosts(3).subscribe((blogs: IBlogPayload) => {
      this.blogPayload = blogs;
    });
  }

  goToBlogPage(blogId: string) {
    this.router.navigate(['blog/', blogId]);
  }
}
