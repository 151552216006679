import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-df-download-banner',
  templateUrl: './df-download-banner.component.html',
  styleUrls: ['./df-download-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DfDownloadBannerComponent implements OnInit {

  constructor() { }

  mediaFile_ES = 'http://dorcalyfiallo.com/media/docs/Bee_Coloring_Book_ES.pdf';
  mediaFile_EN = 'http://dorcalyfiallo.com/media/docs/Bee_Coloring_Book_EN.pdf';

  ngOnInit(): void {
  }

}
