import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as $ from 'jquery';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(function() {

        $(window).on('scroll', function() {
          if ($(this).scrollTop() > 60) {
              $(".df-home .df-home-navigation").addClass('df-home-navigation-bg');
              $(".df-home .df-home-navigation .dorcaly-logo").css({ opacity: 1 });
          } else {
              $(".df-home .df-home-navigation").removeClass('df-home-navigation-bg');
              $(".df-home .df-home-navigation .dorcaly-logo").css({ opacity: 0 });
          }
        });
    });
  }

}
