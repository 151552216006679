import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-categories-banner',
  templateUrl: './categories-banner.component.html',
  styleUrls: ['./categories-banner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CategoriesBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
