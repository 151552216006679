<div class="df-page df-home">
    <div class="df-home-navigation">
        <a [routerLink]="['/inicio']"><img class="dorcaly-logo" src="./../../../assets/imgs/dorcaly_logo.png" /></a>
        <app-navigation></app-navigation>
    </div>
    <app-hero-banner>
        <app-dorcaly-card></app-dorcaly-card>
    </app-hero-banner>
    <div class="df-page-card">
        <app-dorcaly-card></app-dorcaly-card>
    </div>
    <div class="df-page-body container">
        <app-announcement-banner></app-announcement-banner>
    </div>
    <app-intro-banner></app-intro-banner>
    <app-df-download-banner></app-df-download-banner>
    <div class="df-page-body container">
        <app-categories-banner></app-categories-banner>
        <app-blogs-banner></app-blogs-banner>
    </div>
    <!-- <app-subscribe-banner></app-subscribe-banner> -->
    <app-footer></app-footer>
</div>