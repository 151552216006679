<div class="df-hero">
    <div class="df-hero-bg-img"></div>
    <div class="df-hero-bg-overlay"></div>
    <div class='df-hero-logo'>
        <a [routerLink]="['/inicio']"><img class="dorcaly-logo" src="./../../../assets/imgs/dorcaly_logo.png" /></a>
        <img class="dorcaly-signature" src="./../../../assets/imgs/df_signature.png" />
    </div>
    <div class="df-hero-content">
        <span class="df-hero-content1">Aprendiendo</span>
        <span class="df-hero-content2">A Través</span>
        <span class="df-hero-content3">Del Juego</span>
    </div>
    <ng-content></ng-content>
</div>
