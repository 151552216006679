<div class="df-navigation-wrapper">
    <nav class="df-navigation">
        <ul>
            <ng-container *ngFor="let navItem of navItems; let i = index;">
                <li *ngIf="navItem.visible"><a [routerLink]="['/' + navItem.route ]">{{ navItem.label }}</a></li>
                <li *ngIf="navItem.visible && i < navItems.length - 1"><span></span></li> 
            </ng-container>
        </ul>
    </nav>
    <nav class="df-mobile-nav">
        <div class="df-mobile-nav-menu">
            <span (click)="toggleDrawer()" [ngClass]="{'expanded': showMobileNav}" class="df-menu-icon material-icons">menu</span>
        </div>
        <div class="df-mobile-nav-drawer" *ngIf="showMobileNav">
            <ul>
                <ng-container *ngFor="let navItem of navItems">
                    <li *ngIf="navItem.visible" (click)="hideNav()"><a [routerLink]="['/' + navItem.route ]">
                        <span class="material-icons">{{ navItem.icon }}</span>
                        <span class="nav-label">{{ navItem.label }}</span>
                    </a></li>
                </ng-container>
            </ul>
        </div>
        <div class="df-mobile-nav-overlay"></div>
    </nav>
</div>