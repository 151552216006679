import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeES from '@angular/common/locales/es-US';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { BlogPageComponent } from './pages/blog-page/blog-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { BooksPageComponent } from './pages/books-page/books-page.component';
import { VideosPageComponent } from './pages/videos-page/videos-page.component';
import { CoursesPageComponent } from './pages/courses-page/courses-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { HeroBannerComponent } from './components/hero-banner/hero-banner.component';
import { FooterComponent } from './components/footer/footer.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { DorcalyCardComponent } from './components/dorcaly-card/dorcaly-card.component';
import { CategoriesBannerComponent } from './pages/home-page/categories-banner/categories-banner.component';
import { SocialMediaLinksComponent } from './components/social-media-links/social-media-links.component';
import { BlogsBannerComponent } from './pages/home-page/blogs-banner/blogs-banner.component';
import { HeaderComponent } from './components/header/header.component';
import { BlogEntryComponent } from './components/blog-entry/blog-entry.component';
import { SingleBlogPageComponent } from './pages/single-blog-page/single-blog-page.component';
import { PodcastsPageComponent } from './pages/podcasts-page/podcasts-page.component';
import { EqualizerSvgComponent } from './components/equalizer-svg/equalizer-svg.component';
import { registerLocaleData } from '@angular/common';
import { AnnouncementBannerComponent } from './components/announcement-banner/announcement-banner.component';
import { DfDownloadBannerComponent } from './components/df-download-banner/df-download-banner.component';
import { IntroBannerComponent } from './components/intro-banner/intro-banner.component';
import { SubscribeBannerComponent } from './components/subscribe-banner/subscribe-banner.component';
import { TitoBookPageComponent } from './pages/tito-book-page/tito-book-page.component';
import { TitoAppPrivacyComponent } from './pages/tito-app-privacy/tito-app-privacy.component';

registerLocaleData(localeES, 'es-US');

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    BlogPageComponent,
    AboutPageComponent,
    BooksPageComponent,
    VideosPageComponent,
    CoursesPageComponent,
    ContactPageComponent,
    NavigationComponent,
    HeroBannerComponent,
    FooterComponent,
    DorcalyCardComponent,
    CategoriesBannerComponent,
    SocialMediaLinksComponent,
    BlogsBannerComponent,
    HeaderComponent,
    BlogEntryComponent,
    SingleBlogPageComponent,
    PodcastsPageComponent,
    EqualizerSvgComponent,
    AnnouncementBannerComponent,
    DfDownloadBannerComponent,
    IntroBannerComponent,
    SubscribeBannerComponent,
    TitoBookPageComponent,
    TitoAppPrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{
    provide: LOCALE_ID, useValue: 'es-US'
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
