import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-tito-app-privacy',
  templateUrl: './tito-app-privacy.component.html',
  styleUrls: ['./tito-app-privacy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TitoAppPrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
