<div class="df-page df-blog">
    <app-header></app-header>
    <div class="df-page-content container">
        <h1 class="df-page-title"><span class="material-icons">speaker_notes</span> Blog</h1>
        <div class="df-blog-collection">
            <ng-container *ngFor="let blog of blogPayload?.items" >
                <div (click)="goToBlogPage(blog.id)" class="df-blog-entry" blog-entry [blog]="blog"></div>
            </ng-container>
        </div>
    </div>
    <app-df-download-banner></app-df-download-banner>
    <app-footer></app-footer>
</div>