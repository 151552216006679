<div class="intro-banner">
    <div class="intro-banner-inner container">
        <div class="intro-banner-img" [style.background-image]="'url(./assets/imgs/dorcaly-intro.jpg)'">
        </div>
        <div class="intro-banner-content">
            <div class="intro-quote">"Un niño/a siempre siente emociones, pero no siempre puede expresarlas"<span> - Dorcaly Fiallo -</span></div>
            <br/>
            <div class="intro-banner-text">¡Qué alegría tenerte aquí! Soy Dorcaly,  Psicóloga, autora de libros infantiles y certificada en Disciplina Positiva para Familias. Mi pasión es educar a madres, padres y familias sobre los retos de la crianza y el desarrollo integral de los niños/as.</div>
            <div class="intro-banner-links">
                <a [routerLink]="['/sobre-mi']">Conoce Mi Historia Aquí</a>
                <!-- <a [routerLink]="['/charlas']">Charlas Para Padres Aquí</a> -->
            </div>
        </div>
    </div>
</div>