<div class="df-page df-courses">
    <app-header></app-header>
    <div class="df-page-content container">
        <h1 class="df-page-title"><span class="material-icons">mic</span> Podcasts</h1>
        <div class="df-podcast-main-container">
            <div class="df-podcast-episode-wrapper">
                <div class="df-podcast-cover">
                    <div class="df-podcast-cover-overlay"></div>
                    <div class="df-podcast-cover-desc">
                        <div class="df-podcast-cover-desc-top">Pildoras Educativas</div>
                        <div class="df-podcast-cover-desc-bottom">
                            {{ selectedEpisode ? 'Episodio #' + selectedEpisode.epNum : "-----"}}
                        </div>
                    </div>
                    <img class="df-podcast-img" src="./../../../assets/imgs/dorcaly.png" />
                </div>
                <div class="df-podcast-episodes-list">
                    <ng-container *ngFor="let episode of episodes; trackBy: trackByMethod">
                        <div [ngClass]="{ 'playing': episode.isPlaying }" class="df-podcast-single-episode" (click)="onSelectedPodcast(episode)">
                            <div class="episode-row-icon"><span class="material-icons">mic</span></div>
                            <div class="episode-row-info">
                                <div class="episode-row-num">Episodio #{{ episode.epNum }}</div>
                                <div class="episode-row-title">{{ episode.title }}</div>
                                <div class="episode-row-date">{{ episode.published | date }}</div>
                            </div>
                            <div class="episode-row-eq-img">
                                <app-equalizer-svg *ngIf="episode.isPlaying" ></app-equalizer-svg>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="df-podcast-player-wrapper" *ngIf="selectedEpisode">
                <div class="df-podcast-episode-info">
                    <div class="episode-num">Episodio #{{ selectedEpisode.epNum }}</div>
                    <div class="episode-title">{{ selectedEpisode.title }}</div>
                    <div class="episode-desc">{{ selectedEpisode.desc }}</div>
                    <div class="episode-date">Publicado {{ selectedEpisode.published | date }}</div>
                </div>
                <div class="df-podcast-player">
                    <audio style="width: 100%" src="{{ selectedEpisode.path }}" controls ></audio>
                </div>
            </div>
        </div>
    </div>
    <app-footer></app-footer>
</div>