import { Component, OnInit, ViewEncapsulation } from '@angular/core';
//import * as booksJson from './../../../assets/books/books.json';

@Component({
  selector: 'app-books-page',
  templateUrl: './books-page.component.html',
  styleUrls: ['./books-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BooksPageComponent implements OnInit {

  booksList = [
    {
      id: '1',
      title: "The Bee That Didn't Want to Listen",
      author: "by Dorcaly Fiallo",
      lang: 'English',
      description: "Learn about the adventure Honey Johnny, a clueless bee who had a unique experience and learned to listen to his parents while also learning a valuable lesson. This is a love project from the heart of a mom who understands and has lived the journey of a son with special needs. Out of her life story, she passionately felt called to enrich the lives of children and parents with practical stories that will enrich their vocabulary and help them become aware of skills to develop that will keep them safe and thriving.",
      sellers: [
        {
          prelabel: 'Buy On',
          name: "amazon",
          link: 'https://www.amazon.com/Bee-That-Didnt-Want-Listen/dp/1734735678/ref=sr_1_1?crid=3MGN2ZK7FE5KQ&dchild=1&keywords=the+bee+that+didn%27t+want+to+listen&qid=1592271002&s=books&sprefix=the+bee+that+didn%27t%2Cstripbooks%2C156&sr=1-1'
        },
        {
          prelabel: 'Buy On',
          name: "bn",
          link: 'https://www.barnesandnoble.com/w/the-bee-that-didnt-want-to-listen-dorcaly-fiallo/1137162354'
        }
      ]
    },
    {
      id: '2',
      title: "La Abejita Que No Queria Escuchar",
      author: "por Dorcaly Fiallo",
      lang: 'Español',
      description: "Es la historia de una abejita despistada que tuvo una experiencia única, y aprendió a escuchar a sus padres mientras vivía una valiosa lección. Este libro lo he escrito desde el amor de una madre para brindarle a los niños la oportunidad de enriquecer su vocabulario, apoyar el desarrollo del lenguaje, mejorar su escucha activa y tener un vínculo afectivo con sus padres, basado en el respeto mutuo y el amor.",
      sellers: [
        {
          prelabel: 'Compra En',
          name: "amazon",
          link: 'https://www.amazon.com/Abejita-Que-Quer%C3%ADa-Escuchar-Spanish/dp/0578726386/ref=sxts_sxwds-bia-wc-drs1_0?cv_ct_cx=La+Abejita&dchild=1&keywords=La+Abejita&pd_rd_i=0578726386&pd_rd_r=98439df3-e72b-4ba1-af79-23d4f3af0fb2&pd_rd_w=hYiSX&pd_rd_wg=CruAu&pf_rd_p=f3f1f1cd-8368-48df-ac69-94019fb84e3f&pf_rd_r=2NV4GNYCSR27F20AT3AK&psc=1&qid=1599019811&sr=1-1-f7123c3d-6c2e-4dbe-9d7a-6185fb77bc58'
        },
        {
          prelabel: 'Compra En',
          name: "bn",
          link: 'https://www.amazon.com/Abejita-Que-Quer%C3%ADa-Escuchar-Spanish/dp/0578726386/ref=sxts_sxwds-bia-wc-drs1_0?cv_ct_cx=La+Abejita&dchild=1&keywords=La+Abejita&pd_rd_i=0578726386&pd_rd_r=98439df3-e72b-4ba1-af79-23d4f3af0fb2&pd_rd_w=hYiSX&pd_rd_wg=CruAu&pf_rd_p=f3f1f1cd-8368-48df-ac69-94019fb84e3f&pf_rd_r=2NV4GNYCSR27F20AT3AK&psc=1&qid=1599019811&sr=1-1-f7123c3d-6c2e-4dbe-9d7a-6185fb77bc58'
        }
      ]
    },
    {
      id: '4',
      title: "Tito's Special Place",
      author: "by Dorcaly Fiallo",
      lang: 'English',
      description: "\"Tito's Special Place\" is a children’s book that teaches them how to channel their emotions using their words in a healthy way. The story talks about a young artist named Tito, who likes to draw, but often gets frustrated and his emotions get in the way.",
      sellers: [
        {
          prelabel: 'Compra En',
          name: "amazon",
          link: 'https://a.co/d/26TrcYO'
        }
      ]
    },
    {
      id: '5',
      title: "El Lugar Especial de Tito",
      author: "por Dorcaly Fiallo",
      lang: 'Español',
      description: "\"El Lugar Especial de Tito\" es un libro para niños/as que les enseña cómo canalizar sus emociones usando sus palabras y de forma saludable. La historia habla sobre un joven artista llamado Tito, a quien le gusta dibujar, pero a menudo se frustra y sus emociones se interponen en su camino.",
      sellers: [
        {
          prelabel: 'Compra En',
          name: "amazon",
          link: 'https://a.co/d/cACO1mG'
        }
      ]
    },
  ];

  ebookList = [
    {
      thumbnail: 'book3cover',
      title: 'Libro de Colorear "La Abejita Que No Quería Escuchar"',
      totalPages: '6',
      format: 'PDF',
      description: 'Libro de colorear para descargar gratis, para que sus niños se diviertan coloreando a Juancito Miel y su amigos. Disponible en Inglés y Español.',
      links: [
        {
          label: 'Download in English',
          url: 'http://dorcalyfiallo.com/media/docs/Bee_Coloring_Book_EN.pdf',
        },
        {
          label: 'Descargar en Español',
          url: 'http://dorcalyfiallo.com/media/docs/Bee_Coloring_Book_ES.pdf'
        }
      ]
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
